var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col main_topics_wrapper_container"},[_c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$store.getters['topics/usersLoad']),expression:"$store.getters['topics/usersLoad']"}],scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header_wrapping_topics"},[_c('p',{staticClass:"m-0"},[_c('i',{staticClass:"fa fa-list"}),_vm._v(" "+_vm._s(_vm.localTopic.name)+" ")]),_c('l-button',{staticClass:"version_btns",attrs:{"type":"info"},on:{"click":function($event){return _vm.open_topic_dialog(1, _vm.localTopic)}}},[_c('el-icon',{staticClass:"el-icon-plus"}),_vm._v(" "+_vm._s(_vm.localization("Add Version"))+" ")],1)],1),_c('div',{staticClass:"hidden-md-only hidden-lg-only hidden-xl-only"},[_c('Info',{attrs:{"items":_vm.localTopicForInfo}})],1)]},proxy:true}])},[_c('el-tree',{attrs:{"data":_vm.localTopic.children,"default-expand-all":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{staticClass:"custom-tree-node w-100"},[_c('div',{},[_vm._v(_vm._s(data.title))]),_c('div',{staticClass:"main_wrapping_for_strategy "},[(node.level == 2)?_c('div',[_c('i',{staticClass:"fa fa-list mr-1"}),_vm._v(" "+_vm._s(data.name)+" ")]):_vm._e(),(node.level == 2)?_c('div',{staticClass:"content_name_wrapper"},[_c('l-button',{staticClass:"active_button",attrs:{"outline":"","round":"","size":"sm","type":data.removed == 0 ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(data.removed == 0 ? ("" + (_vm.localization("Active"))) : ("" + (_vm.localization("Inactive"))))+" ")]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(("" + (_vm.localization('Edit Topic')))),expression:"`${localization('Edit Topic')}`",modifiers:{"top-center":true}}],staticClass:"edit_strategy",on:{"click":function($event){return _vm.open_topic_dialog(2, data)}}},[_c('i',{staticClass:"fa fa-edit"})]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
                data.removed == 0 ? 'Inactivate Topic' : 'Active Topic'
              ),expression:"\n                data.removed == 0 ? 'Inactivate Topic' : 'Active Topic'\n              ",modifiers:{"top-center":true}}],staticClass:"edit_strategy",on:{"click":function($event){return _vm.remove(data.id, data.removed)}}},[(data.removed == 0)?_c('i',{staticClass:"fa fa-close "}):_c('i',{staticClass:"fa fa-check text-success"})]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(("" + (_vm.localization('Add Tasks')))),expression:"`${localization('Add Tasks')}`",modifiers:{"top-center":true}}],staticClass:"edit_strategy",on:{"click":function($event){return _vm.goToVersions(data.id)}}},[_c('i',{staticClass:"fa fa-plus"})])],1):_vm._e()])])}}])})],1),_c('el-dialog',{staticClass:"add_topics_dialog",attrs:{"title":_vm.type == 1
        ? ("" + (_vm.localization('Add Version')))
        : ("" + (_vm.localization('Edit Version'))),"width":"60%","visible":_vm.topic_dialog,"top":"4vh"},on:{"update:visible":function($event){_vm.topic_dialog=$event}}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.topic,"rules":_vm.rules,"label-position":"top"}},[_c('el-form-item',{attrs:{"label":("" + (_vm.localization('Name'))),"label-width":"100px","prop":"name"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"autocomplete":"off"},model:{value:(_vm.topic.name),callback:function ($$v) {_vm.$set(_vm.topic, "name", $$v)},expression:"topic.name"}})],1),_c('el-form-item',{attrs:{"label":("" + (_vm.localization('Language'))),"label-width":"100px","prop":"language"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":("" + (_vm.localization('Please select language')))},model:{value:(_vm.topic.language),callback:function ($$v) {_vm.$set(_vm.topic, "language", $$v)},expression:"topic.language"}},_vm._l((_vm.languages),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.title,"value":item.id}})}),1)],1)],1),_c('span',{staticClass:"\n        dialog-footer\n        dialog_footer\n      ",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"text-center text-capitalize save_button delete_button",on:{"click":function($event){_vm.topic_dialog = false}}},[_vm._v(_vm._s(_vm.localization("Cancel")))]),_c('el-button',{staticClass:"text-center text-capitalize save_button ",attrs:{"type":""},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.localization("Save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }