<template>
  <div class="col main_topics_wrapper_container">
    <el-card v-loading="$store.getters['topics/usersLoad']">
      <template #header>
        <div class="header_wrapping_topics">
          <p class="m-0">
            <i class="fa fa-list"></i>
            {{ localTopic.name }}
          </p>
          <l-button
            type="info"
            class="version_btns"
            @click="open_topic_dialog(1, localTopic)"
          >
            <el-icon class="el-icon-plus"></el-icon>
            {{ localization("Add Version") }}
          </l-button>
        </div>
        <div class="hidden-md-only hidden-lg-only hidden-xl-only">
          <Info :items="localTopicForInfo" />
        </div>
      </template>

      <!-- //? Start Tree Node  -->
      <el-tree :data="localTopic.children" default-expand-all>
        <div class="custom-tree-node w-100" slot-scope="{ node, data }">
          <div class="">{{ data.title }}</div>
          <!--//? start main wrapper for topics  -->
          <div class="main_wrapping_for_strategy ">
            <!-- //? start part one  -->
            <div v-if="node.level == 2">
              <i class="fa fa-list mr-1"></i>
              {{ data.name }}
            </div>
            <!-- //? end part one  -->

            <!-- //? start part two  -->
            <div v-if="node.level == 2" class="content_name_wrapper">
              <l-button
                outline
                round
                size="sm"
                :type="data.removed == 0 ? 'success' : 'danger'"
                class="active_button"
              >
                {{
                  data.removed == 0
                    ? `${localization("Active")}`
                    : `${localization("Inactive")}`
                }}
              </l-button>
              <a
                v-tooltip.top-center="`${localization('Edit Topic')}`"
                @click="open_topic_dialog(2, data)"
                class="edit_strategy"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-tooltip.top-center="
                  data.removed == 0 ? 'Inactivate Topic' : 'Active Topic'
                "
                @click="remove(data.id, data.removed)"
                class="edit_strategy"
              >
                <i v-if="data.removed == 0" class="fa fa-close "></i>
                <i v-else class="fa fa-check text-success"></i>
              </a>

              <a
                @click="goToVersions(data.id)"
                v-tooltip.top-center="`${localization('Add Tasks')}`"
                class="edit_strategy"
              >
                <i class="fa fa-plus"></i>
              </a>
            </div>
            <!-- //? end part two  -->
          </div>
        </div>
      </el-tree>
    </el-card>

    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Version')}`
          : `${localization('Edit Version')}`
      "
      width="60%"
      :visible.sync="topic_dialog"
      top="4vh"
      class="add_topics_dialog"
    >
      <el-form
        :model="topic"
        ref="ruleForm"
        :rules="rules"
        label-position="top"
      >
        <el-form-item
          :label="`${localization('Name')}`"
          label-width="100px"
          prop="name"
        >
          <el-input
            v-model="topic.name"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="`${localization('Language')}`"
          label-width="100px"
          prop="language"
        >
          <el-select
            v-model="topic.language"
            :placeholder="`${localization('Please select language')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in languages"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="Category" label-width="100px" prop="category">-->
        <!--          <el-select v-model="topic.category" clearable placeholder=" Topic" style="width: 100%">-->
        <!--            <el-option v-for="(item, index) in wall" :key="index" :label="item.label" :value="item.label"></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="Default Task" label-width="100px" prop="taskDefault">-->
        <!--          <el-input v-model="topic.taskDefault" autocomplete="off" style="width: 100%"></el-input>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="Description" label-width="100px" prop="description">-->
        <!--          <el-input v-model="topic.description" autocomplete="off" style="width: 100%"></el-input>-->
        <!--        </el-form-item>-->
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="topic_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          class="text-center text-capitalize save_button "
          type=""
          @click="save"
          >{{ localization("Save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Info from "../../components/Cards/Info";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";

export default {
  components: {
    Info
  },
  data: () => ({
    languages: [],
    topic_dialog: false,
    topic: {},
    rules: {
      name: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      category: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }
      ],
      language: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }
      ],
      taskDefault: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      description: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ]
    },
    type: null,
    isThisEnglish: false
    // wall: [{label: "default"}, {label: "workforce"}],
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    localTopic() {
      return this.$store.getters["topics/topic"];
    },
    localTopicForInfo() {
      return Object.entries(this.localTopic)
        .map(([key, value]) => {
          return { key, value };
        })
        .filter(
          item =>
            item.key !== "id" &&
            item.key !== "old_id" &&
            item.key !== "parent_id" &&
            item.key !== "removed" &&
            item.key !== "isEnabled" &&
            item.key != "children"
        );
    }
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      this.$store.dispatch("topics/topic", { id: this.id }).then(_ => {
        this.languages = this.$store.getters["topics/languages"];
        if (this.localTopic) {
        }
      });
    },
    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("topics/AddTopics", { query: this.topic })
              .then(_ => {
                this.Refresh({});
                this.topic_dialog = false;
              });
          } else {
            this.$store
              .dispatch("topics/UpdateTopics", {
                id: this.topic.id,
                query: this.topic
              })
              .then(_ => {
                this.Refresh({});
                this.topic_dialog = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    remove(id, removed) {
      Swal.fire({
        title: "Are you sure?",
        text:
          removed == 0
            ? `are you sure to delete this version?`
            : "are you sure to Active this version",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Confirm",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("topics/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: removed == 0 ? `Deleted` : "Actived",
              text:
                removed == 0
                  ? "Version has been deleted."
                  : "Version has been actived.",
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false
            });
          });
        }
      });
    },
    open_topic_dialog(type, item) {
      delete item.children;
      delete item.language_name;
      delete item.language_id;
      this.topic = item;
      if (type == 1) {
        this.topic.parent_id = parseInt(this.id);
        this.topic.name = "";
        delete item.id;
      }
      this.Refresh();
      this.topic_dialog = true;
      this.type = type;
    },
    goToVersions(id) {},
    isThisEnglishVersion() {
      this.localTopic.children.forEach(element => {
        if (element.title.toLowerCase() == "english") {
          // this.isThisEnglish = true
          console.log(element);
          console.log(element);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-tree-node__content {
  height: auto !important;
  // background: #eee;
  padding: 1rem 0 !important;
}

::v-deep .el-tree-node {
  white-space: unset !important;
  outline: 0;
}

.header_wrapping_topics {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
  }
  .version_btns {
    min-width: 150px;
    height: fit-content;
    @media (min-width: 320px) and (max-width: 767px) {
      margin: 1rem 0 !important;
      display: block;
    }
  }
}

.main_wrapping_for_strategy {
  display: flex;
  justify-content: space-between;
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
  }
  .content_name_wrapper {
    min-width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: fit-content;
    @media (min-width: 320px) and (max-width: 767px) {
      justify-content: unset;
      gap: 25px;
      margin-top: 15px;
    }
    .active_button {
      display: block;
    }
    .edit_strategy {
      display: block;
      width: 30px;
      height: 30px;
      background-color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      padding: 1px;
      border-radius: 50%;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 0 5px -2px rgba(0, 0, 0, 0.1);
      transition: 0.3s all;
      color: #777;
      &:hover {
        background-color: #23ccef;
        color: #fff;
      }
    }
  }
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    background-color: #23ccef;
    border-color: #23ccef;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>

<style lang="scss">
.main_topics_wrapper_container {
  .add_topics_dialog {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
</style>
